import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import wx from "weixin-js-sdk"
import "normalize.css"
import axios from "axios";
import Vant from 'vant';
import 'vant/lib/index.css';
import vueEsign from "vue-esign";
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
Vue.use(vueEsign)
Vue.use(Vant);

//CJL  挂在vue的原型上方便使用
Vue.prototype.$axios = axios;
Vue.prototype.$wx = wx;

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
