<template>
  <div>
    <pdf ref="pdf" class="pdf-preview"
         v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
    <div style="height: 200px"></div>
    <div v-if="remindMessage != '签署成功！'" class="sign-div">
      <button class="sign-button" @click="popupSignPopup">
        <div style="font-weight: bold;font-size: 16px">签署</div>
      </button>
      <div>
      <div class="consent" @click="consent = !consent">
        <van-checkbox  v-model="consent" checked-color="#67C23A" icon-size="14px"></van-checkbox>
        <div style="margin-left: 4px">已阅读并同意合同协议</div>
      </div>
      </div>
    </div>

    <van-popup position="top" style="height:100%;background-color: #F0F2F5" v-model="signPopup">
      <div>
        <div style="background-color: white;margin:20px 44px 20px 60px;display: flex;justify-content: center;">
          <div style="transform: rotate(90deg);position: absolute;display: flex;top: 50%;left: -150px">
            <van-button round type="danger" style="width: 100px;margin: 0 10px" @click="signPopup=false"> 退 出 </van-button>
            <van-button round type="default" style="width: 100px;margin: 0 10px" @click="reset()"> 清 空 </van-button>
            <van-button round type="primary" style="width: 100px;margin: 0 10px" @click="generateImage"> 完 成 </van-button>
          </div>
          <vue-esign ref="esign" :width="width" :height="height"
                     :isCrop="false" :lineWidth="6" lineColor="#000000" bgColor.sync="#ccc" />
        </div>
        <div style="transform: rotate(90deg);position: absolute;top: 50%;right: -4px">
          <div style="font-weight: bold;font-size: 20px">签名区</div>
        </div>
      </div>
      <van-overlay :show="signConfirmPopup" @click="signConfirmPopup = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <div style="font-weight: bold;font-size: 18px">提醒</div>
            <div style="margin-top: 20px;font-size: 16px">
              {{remindMessage}}
            </div>
            <div class="sign-final">
              <div class="cancel" @click="signConfirmPopup = false">取消</div>
              <div v-if="!signConfirmLoading" class="confirm"  @click="signContract">确定</div>
              <div v-if="signConfirmLoading" class="confirm">
                <van-loading size="20px" type="spinner" />
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
    </van-popup>



  </div>
</template>
<script>
import pdf from 'vue-pdf'
import { Toast } from 'vant';
import {userSignContract} from '@/request/api';
export default {
  components: {
    pdf
  },
  data() {
    return {
      consent:true,
      remindMessage:"确定签名无误并签署合同吗？",
      signConfirmLoading:false,
      signConfirmPopup:false,
      width:0,
      height:0,
      signPopup:false,
      base64:"",
      defaultUrl:"https://minio.yanhuotiyu.com/contract/studentContract/",
      url:"",
      numPages: null, // pdf 总页数
    }
  },
  methods:{
    /**
     * 弹出签名框
     */
    popupSignPopup(){
      if (this.consent){
        this.signPopup = true;
      }else {
        Toast({ icon: 'fail', message: '请勾选底部协议！' });
      }
    },
    /**
     * 清空签名框内容
     */
    reset() {
      this.$refs.esign.reset();
    },
    /**
     * 检查是否签字，
     * 是：弹出确认框！
     * 否：提示签字！
     */
    generateImage(){
      this.$refs.esign.generate().then((base64) => {
        this.base64 = base64;
        this.signConfirmPopup = true;
      }).catch((err) => {
        Toast({
          message: '未检查到签名！',
          icon: 'fail',
        });
      });
    },
    /**
     * 确认签署
     */
    signContract(){
      this.remindMessage = "请稍等，签署中！";
      this.signConfirmLoading = true;
      userSignContract({base64:this.base64}).then(val=>{
        this.signConfirmLoading = false;
        if (val.code == 200){
          this.url = val.data;
          this.remindMessage = "签署成功！";
          //合同打开较慢，此处延迟1秒，避免白屏过长！
          setTimeout(() => {this.signConfirmPopup = false;}, 1000);
          setTimeout(() => {this.signPopup = false;}, 2000);
        }else {
          this.remindMessage = val.message;
        }
      })
    },
    /**
     获取请求中携带的参数
     */
    getUrlParameter(){
      let hash = window.location.hash;
      let data = hash.slice(hash.indexOf('?') + 1);
      let pairs = data.split('&');
      let params = {};
      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        params[key] = value;
      }
      sessionStorage.setItem("appToken",params.appToken);
      sessionStorage.setItem("nowTime",params.nowTime);
      return params;
    }
  },
  created() {
    Toast.loading({
      message: '请稍等...',
      forbidClick: true,
      loadingType: 'spinner',
      duration:10000
    });
    let data = this.getUrlParameter();
    this.url = this.defaultUrl+data.contractSite;
    let loadingTask = pdf.createLoadingTask(this.url)
    loadingTask.promise.then(pdf => {
      this.numPages = pdf.numPages;
      setTimeout(() => {
        Toast({ icon: 'down', message: '下滑至底部进行签署！' });
      }, 1500);
    })
    this.width = document.documentElement.clientWidth-110; //实时宽度
    this.height = document.documentElement.clientHeight-50; //实时高度
  }
}
</script>

<style scoped>
.pdf-preview {
  width: 100%;
//flex: 1;
//display: none;
  height: 78vh;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78%;
}

.block {
  transform: rotate(90deg);
  width: 300px;
  height: 120px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 0;
}
.pdf-preview canvas {
  height: 78% !important;
}
.cancel{
  width: 50%;border-right: 1px solid #E6E8EB;padding: 8px 0
}
.confirm{
  color: #67C23A;
  width: 50%;
  padding: 8px 0
}
.sign-final{
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.sign-div{
  bottom: 0;
  padding:10px 20px 20px 20px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 100%;
}
.consent{
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  line-height: 24px;
}
.sign-button{
  width:150px;
  height: 46px;
  border-radius: 16px;
  border: none;
}
</style>