import axios from 'axios'

 const baseUrl = 'https://minprogram.yanhuotiyu.com'
 //const baseUrl = 'http://192.168.10.102:9099'
// 设置axios的返回拦截（还可以设置request拦截，这里暂时用不上）
axios.interceptors.request.use(
    // 每次请求都自动携带Cookie
    config => {
        if (sessionStorage.getItem("nowTime") && sessionStorage.getItem("appToken")){
            config.headers['nowTime'] = sessionStorage.getItem("nowTime");
            config.headers['appToken'] = sessionStorage.getItem("appToken");
        }
        return config;
    },
    // eslint-disable-next-line handle-callback-err
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
            // alert('当前网络环境弱，试试其他网络');
            return Promise.reject(error);          // reject这个错误信息
        }
        return Promise.reject(error);
    })
export default (url = '', data = {}, type = 'GET', ContentType) => {
    return new Promise((resolve, reject) => {
        let requestConfig = {
            method: type,
            url: baseUrl + url,
            headers: {
                'Content-Type': ContentType ? ContentType : 'application/json',
                // 'token':localStorage.getItem('tokens') || null
            },
            timeout: 20000
        };
        switch (type.toUpperCase()) {
            case "GET":
                requestConfig.params = data;
                break;
            case "POST":
                requestConfig.data = data
                break;
        }
        axios(requestConfig)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
