<template>
  <div style="text-align: left;padding:10px;">
    <div class="item" >
      <van-cell-group title="姓名" style="width: 60px;margin-right: 6px"></van-cell-group>
      <van-field style="border-radius: 10px" v-model="item.studentName" placeholder="请输入姓名"/>
    </div>
    <div class="item">
      <van-cell-group title="性别" style="width: 60px;margin-right: 6px"></van-cell-group>
      <van-cell :title="item.studentSex == 0 ? '女':'男'" style="border-radius: 10px" is-link @click="openChoiceSex" />
    </div>

    <div class="item">
      <van-cell-group title="年级" style="width: 60px;margin-right: 6px"></van-cell-group>
      <van-cell :title="item.grade" style="border-radius: 10px" is-link  @click="openChoiceGrade" />
    </div>
    <div style="text-align: center;margin-top: 100px">
      <van-button @click="confirm" type="primary" style="border-radius: 10px;padding:10px 36px">确 定</van-button>
    </div>





    <van-popup v-model="choiceSexDialog" round position="bottom">
      <div style="height: 300px;padding-top: 10px">
        <van-picker :columns="sex" title="选择性别" show-toolbar @cancel="closeDialog" @confirm="choiceSex"/>
      </div>
    </van-popup>
    <van-popup v-model="choiceGradeDialog" round position="bottom">
      <div style="height: 300px;padding-top: 10px">
        <van-picker :columns="columns" title="选择年级" show-toolbar @cancel="closeDialog" @confirm="choiceGrade"/>
      </div>
    </van-popup>








  </div>
</template>

<script>
import {selectStudent,updateStudentByTempSign} from '@/request/api';
import { Toast } from 'vant'
export default {
  name: "UpdateStudent",
  data() {
    return {
      item:{},
      sex: ['女', '男'],
      columns: ['幼儿','一年级', '二年级', '三年级', '四年级', '五年级','六年级',"初一","初二","初三","高一","高二","高三"],
      choiceSexDialog:false,
      choiceGradeDialog:false
    }
  },
  methods:{
    confirm(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      updateStudentByTempSign(this.item).then(val=>{
        if (val.code == 200){
          Toast.success('更新成功！');
          setTimeout(() => {
            location.href = this.item.toMiniParam.openlink
          }, 2000);
        }else {
          Toast.fail('更新失败！');
        }
      })
    },
    openChoiceGrade(){
      this.choiceGradeDialog = true;
    },
    openChoiceSex(){
      this.choiceSexDialog = true;
    },
    choiceGrade(val){
      this.item.grade = val;
      this.columns.forEach((item, index) => {
        if (item === val){
          this.item.intGrade = index;
        }
      });
      this.closeDialog();
    },
    choiceSex(val) {
      this.item.studentSex = val == '男' ? 1:0;
      this.closeDialog();
    },
    closeDialog(){
      this.choiceSexDialog = false;
      this.choiceGradeDialog = false;
    }
  },
  created() {
    let hash = window.location.hash;
    let data = hash.slice(hash.indexOf('?') + 1);
    let pairs = data.split('&');
    let params = {};
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      params[key] = value;
    }

    sessionStorage.setItem("appToken",params.appToken);
    sessionStorage.setItem("nowTime",params.nowTime);
    selectStudent(params.appToken).then(val=>{
      if (val.code == 200){
        this.item = val.data;
        if (this.item.intGrade > 12){
          this.item.intGrade = 12
        }
        this.item.grade = this.columns[this.item.intGrade]
      }
    })
  }
}
</script>

<style scoped>
.item{
  background-color: #F5F7FA;
  padding: 12px 10px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  align-content: center;
}
</style>