<template>
  <div class="signup-container">
    <img src="https://codeimg.yanhuotiyu.com/YanHuoAppImages/PublicMarkImage/zhengzhou_test20240810.jpg"
         alt="焱火体育2024全真模拟体测"
         class="signup-image">
    <form @submit.prevent="handleSubmit">
      <h2>焱火体育(郑州)2024全真模拟体测</h2>
      <h3>报名表</h3>
      <!-- 表单输入项 -->
      <van-field class="formItem" readonly="readonly"
                 v-model="formData.city"
                 name="区域"
                 label="区域："
                 placeholder="区域"
                 :rules="[{ required: true, message: '请选择区域' }]"
      />

      <van-field class="formItem"
                 v-model="formData.name"
                 name="姓名"
                 label="姓名："
                 placeholder="请输入学生姓名"
                 :rules="[{ required: true, message: '请输入学生姓名' }]"
      />

      <van-radio-group v-model="formData.sex" direction="horizontal">
        <label style="font-size:large; margin-left: 65px;color: #646566;">性别：</label>
        <van-radio style="margin-left: 35px" name="男">男</van-radio>
        <van-radio style="margin-left: 35px" name="女">女</van-radio>
      </van-radio-group>

      <van-field class="formItem"
                 v-model="formData.phone"
                 name="手机号"
                 label="手机号："
                 placeholder="请输入家长手机号"
                 :rules="[{ required: true, message: '请输入家长手机号' }]"
      />

      <!-- 安全责任书勾选框 -->
      <div class="formItem">
        <van-checkbox id="agree" v-model="agreed"><label for="agree">我已阅读并同意<a
            @click="showDialog=true">《安全责任书》</a></label></van-checkbox>
      </div>
      <van-button round type="info" size="large" style="margin: 20px 0px">立即报名</van-button>
    </form>
    <!-- 提交后的展示内容 -->
    <div v-if="showSuccess" class="success-message">
      <p>报名成功！随机码：{{ registrationNumber }}</p>
    </div>

    <!--安全责任书内容-->
    <van-dialog v-model="showDialog" @confirm="agreed=true" title="焱火体育2024全真模拟体测" width="400">
      <!--      <div style="width: 320px;padding-left: 30px">
              <div style="text-align: center;font-size: large;font-weight: bold;">
                参赛安全责任书
              </div>
              <div class="retract">
                1.我完全了解自己的身体状况，确认自己的健康状况良好，没有任何身体不适或疾病(包括先天性心脏病、风湿性心脏病、高血压、脑血管疾病、心肌炎、其他心脏病冠状动脉病、严重心律不齐、血糖过高或过低的糖尿病、以及其它不适合跑步运动的疾病)，因此我郑重声明，可以正常参加比赛。
              </div>
              <div class="retract">
                2.我充分了解本次比赛期间的训练或比赛有潜在的危险，以及可能由此而导致的受伤或事故，我会竭尽所能，以对自己的安全负责任的态度参赛。
              </div>
              <div class="retract">
                3.我本人愿意遵守本次比赛的所有规则规定:如果本人在参赛过程中发现或注意到任何风险和潜在风险，本人将立刻终止参赛并告知组委会。
              </div>
              <div class="retract">
                4.我本人以及我的继承人、代理人、个人代表或亲属将放弃追究所有导致伤残、损失或死亡的权利。
              </div>
              <div class="retract">
                5.我同意接受主办方在比赛期间提供的现场急救性质的医务治疗，但在医院救治等发生的相关费用由本人负担。本人已认真阅读全面理解以上内容，且对上述所有内容予以确认并承担相应的法律责任，本人签署此责任书纯属自愿。
              </div>
            </div>-->

      <div style="height:640px;width: 320px;padding-left: 30px;overflow-y: auto;">
        <div style="text-align: center;font-size: large;font-weight: bold;">
          体育活动安全责任说明
        </div>
        <div class="retract">
          <br/>
          尊敬的活动参与者：
          <br/>
          欢迎您使用本次焱火体育小程序报名参加训练、比赛等活动。
          <br/>
          焱火体育提醒您：本小程序由焱火体育公司运营，涉及的具体用户服务，将由焱火体育公司或关联的公司或有许可的服务商提供。如果用户（以下简称：“您”或“活动参与人”或“学员”）在本小程序进行访问、报名训练或报名参加活动的，便视为用户接受了以下体育活动安全责任免说明，请仔细阅读以下各项内容，尤其是以下加粗字体。如果您或活动参与人不同意以下任何内容，请立即停止访问或停止报名参加任何性质的训练、比赛等活动。
          <br/>
        </div>
        <div class="retract" style="font-size: large">
          <br/>
          <label style="font-weight: bold">第一部分 基本条款</label>
          1、本体育活动安全责任说明全称：体育训练、赛事等活动风险及安全警示告知免责说明。请您仔细阅读并完全理解以下说明内容以及活动场所内可能可能会存在的运动风险。当您访问及确认报名后，即视为您和活动参与人员已经被焱火体育公司或关联的公司告知并完全理解活动及活动场所内运动存在的风险，以及运动导致的损伤和伤病等，并同意遵守本说明内容，避免危险及不允许的动作，听从活动内工作人员的管理。
          <br/>
          2、您完全了解活动参与人及自身的身体状况，确认活动参与人及自身的健康状况良好，没有任何身体不适或疾病等其他所有医学认为不适合运动的疾病和精神状况，以及其它不适合体育训练或活动的疾病，可以正常参加体育训练、比赛等活动。
          <br/>
          3、请自行保管好您及活动参与者的个人物品及确保自身安全，焱火体育不负有看护和管理责任，个人物品等请自行保管，如有遗失或损坏，需自行承担损失；参加的学员及监护人应当加强安全、监护力度，在活动场所之外以及在时间以外发生的伤害等，焱火体育不承担责任。
          <br/>
          4、活动或训练期间，参加的学员不得随意外出。有事外出，需要向工作人员请假，经准许签字后方可离开，离开训练活动场所范围造成社会危害或出现意外事故的，由参加的学员及监护人自行承担一切责任。
          <br/>
          5、训练或活动结束后，自行滞留训练场所内，在非活动、训练期间发生的伤害，焱火体育不承担任何法律责任。
          <br/>
          6、活动参与者在训练场所造成社会危害的，或对第三方的人身或财产造成损害的，由活动参与者及其监护人负责经济赔偿及承担全部责任，触犯法律的，由相关部门依法处理。
        </div>
        <div class="retract">
          <br/>
          <label style="font-weight: bold">第二部分 活动安全须知</label>
          <br/>
          1、活动参与者进入训练场所，请自觉遵守本说明，遵守场所安全使用规范等规定，训练及活动结束后，请及时归还借用器材，按时离场。
          <br/>
          2、非训练或活动参与人员，不得进入场所进行活动，私自进入场所活动，发生一切受伤及财产损失等所有损害或侵权事件，均由其自行承担。
          <br/>
          3、请陪同人或家长（监护人）根据未成年人的身体状况，选择合适及可适应的体育训练项目或活动项目，请陪同人或家长（监护人）善尽监护的责任，陪同人或家长（监护人）须为年满18周岁以上行动方便的成年人。
          <br/>
          4、各类交通工具及宠物均不得进入训练场所，包括踏板车、滑板（车）、溜冰鞋等。
          <br/>
          5、进入训练场所，不得玩火、玩电、玩炮、玩锐钝器、焚烧废纸、点燃蜡烛、私安电器、乱拉电线等；身上不得携带硬物、尖物等易致人受伤的物品（包括：玩具、发卡、饰物等）。
          <br/>
          6、训练场所内严禁乱跑乱窜，严禁争抢、推挤，要排队遵守秩序，防止撞伤、摔伤以及伤害到自身或他人。
          <br/>
          7、活动参与人员要正确使用训练器材、设备设施等，训练、活动时禁止做任何危险动作或向他人作出危险动作，对自身或对第三人的人身或财产造成损害，遵从工作人员指导动作，自身做好防护意识及措施，要注意安全。
          <br/>
          8、在训练场场所禁止从设备设施或器材高处直接跳下，禁止跨越存在的安全保护线（网）或在保护栏（网）上攀爬嬉闹等，严禁攀爬、拉扯场所内及周边安全设备设施等不安全行为。
          <br/>
          9、活动参与人员必须自觉爱护训练设施及公私财物，不得在训练设施上乱涂、乱画、乱刻、乱砸、乱拆等有损公私财物的行为。损坏相关设备设施或器材的，根据损坏的程度，应当照价赔偿并追究相关责任。
          <br/>
          10、活动参与人员必须自觉爱护公共卫生，请维护好场所清洁,不得随地大小便、乱扔果皮、杂物、石子、树枝等，有违反规定者，其陪同人员或家长（监护人）应自觉承担清洁等责任；禁止在训练场所内随地吐痰，如遇呕吐或突发事件，请及时与工作人员联系，以便得到妥善处理。
          <br/>
          11、活动参与人员进入训练场所，陪同人或家长（监护人）应确保活动参与人员的身体健康良好，适合各项目体育训练，为保证健康和安全，请您确认您及活动参与人的身体状况可以参加体育训练及体育活动等，没有任何不适或疾病（包括但不限于心脏病、传染病、精神病、痴呆症、高血压、脑血管疾病、心肌炎、其他心脏病冠状动脉病、严重心律不齐、血糖过高或过低的糖尿病、急性肾炎或慢性肾炎、急性肾盂肾炎、各种类型肺炎、慢性支气管炎急性发作、支气管哮喘、脑出血急性期、活动性内脏出血、哮喘等其他所有医学认为不适合运动的疾病和精神状况，以及其它不适合体育训练或活动的疾病），参与训练或活动。
          <br/>
          12、在活动或训练期间或场所内，不负有看管责任，贵重物品及随身物品请活动参与人员、陪护人或家长（监护人）自行保管，如有遗失或损坏概不负责。
          <br/>
          13、活动或训练场所内若发生突发事件或设备设施出现故障时，立刻联系相关工作人员或客服中心，要保持镇定，不要恐慌，并听从相关工作人员安排，以便及时处理。
          <br/>
          14、活动或训练场所只负责活动参与人员因教学事故及场所内无第三方责任人造成的事故损伤，而不负责由于游玩、运动、娱乐本身或第三人侵权而引发的损伤，不负责非因焱火体育教学训练问题造成的人身损害及财产损失。
          <br/>
          15、在活动或训练场所，凡违反场所训练管理规则及本说明，活动参与人员或家长（监护人）未尽到监护责任或因第三人等原因导致自身或他人的权益受到损害的，将由责任人或家长（监护人）承担责任。
          <br/>
          16、监护人或陪同人员，违反场地安全规定和本说明，扰乱训练或活动秩序，伤害教练、工作人员或其他活动参与人等侵权行为的，监护人或陪同人员依法承担相应法律责任，触犯法律的，由相关部门依法处理。
        </div>
        <div class="retract">
          <br/>
          <label style="font-weight: bold">第三部分 健康与安全</label>
          <br/>
          1、活动参与人员及监护人，再次特此声明，您对活动参与人及自身身体状况完全了解并确认身体状态良好，没有任何因药物或其他原因不能进行本活动、训练或比赛的情况存在。
          <br/>
          2、您完全认可并理解在活动、训练、比赛或其场所内，您及活动参与人员活动或进行体育运动具有一定危险性，并愿意承担风险，并充分了解进行的训练、活动、比赛期间的所有潜在的危险，以及可能由此而导致的受伤或事故等，您及活动参与人会竭尽所能，以对自身的安全负责任的态度参加活动、训练、比赛，其风险及责任会由您及活动参与人自行承担。
          <br/>
          3、您及活动参与人员愿意遵守本训练或活动的所有规则规定：如果您及活动参与人员在参赛过程中发现或注意到任何风险和潜在风险，将立刻终止训练或活动，并告知相应工作人员。
          <br/>
          4、您及活动参与人员，以及相应的继承人、代理人、个人代表或亲属将放弃追究所有导致伤残、损失或死亡的权利。
          <br/>
          5、您及活动参与人员同意接受主办方在训练、活动、比赛期间提供的现场急救性质的医务治疗，但在医院救治等发生的相关费用由您及活动参与人员负担。您及活动参与人员已认真阅读全面理解以上内容，且对上述所有内容予以确认并承担相应的法律责任。
          <br/>
          6、任何到训练或活动场所参观、训练、使用活动设施设备器具的人员同样适用本安全责任说明条款。
          <br/>
          7、对于因不可抗力其他的原因造成的训练或活动终止，若发生不能控制的不可抗力事件，包括但不限于法定不可抗力事件和任何台风，水灾、旱灾，火灾等自然灾害或战争、饥荒，罢工等原因，而导致您及活动参与人无法按约训练及活动或场所无法正常运营时，您及活动参与人与焱火体育之间互不承担违约责任，同时焱火体育及场所将尽一切努力力争影响消除后恢复运营，焱火体育将尽力减少因此而给您造成的损失和影响。
          <br/>
          8、本免责声明与中华人民共和国法律解析一致包括法律条款中有争议抵触的内容，您和焱火体育一致同意服从法院的管辖。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响。因解释本免责声明以及用户与焱火体育产生的争议，将同意接受焱火体育所在地郑州金水区人民法院的管辖。
        </div>

      </div>
    </van-dialog>

  </div>
</template>


<script>
import {AddEnrollment} from '@/request/api';
import 'vant/lib/button/index.css';

export default {
  //外部组件
  components: {},
  //数据
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        city: '郑州',
        areaFlag: 1,
        sex: '男',
        // ...其他表单字段
      },
      showSuccess: false,
      registrationNumber: '',
      showDialog: false, // 控制Dialog的显示与隐藏
      agreed: false, // 用户是否同意安全责任书
      showAreaFlag: false,//是否显示城市类型选项
      areaFlagColumnList: [{text: '中招'}, {text: '郑州', disabled: true}, {text: '高考', disabled: true}],//城市类型选项
      cityOptionList: [
        {value: '郑州', text: '郑州', startLimitDate: '2024-08-29 23:59:59'},//平顶山开始限制的时间是2024年7月16日24时
      ],
    };
  },
  //方法
  methods: {
    //select中option某项是否禁用
    isDisabled(optionValue) {
      const option = this.cityOptionList.find(o => o.value === optionValue);
      if (!option) return false; // 如果找不到选项，则不执行禁用
      const startLimitDate = new Date(option.startLimitDate);//某个option选项开始禁用的时间
      return new Date() >= startLimitDate;//当前时间 若晚于或等于当前 开始限制的日期,则禁止选择
    },
    handleConfirm() {
      this.agreed = true;
      this.showDialog = false;
      // 可以在这里进行后续操作，比如提交表单
      this.handleSubmit();
    },
    handleCancel() {
      this.agreed = false;
      this.showDialog = false;
    },
    //选择城市改变交互
    changeCity() {
      this.$toast('当前选择城市' + this.formData.city)
      this.showAreaFlag = this.formData.city === '郑州';
    },
    //点击城市类型确认
    onAreaFlagConfirm(value, index) {
      // this.$toast(`当前值：${value}, 当前索引：${index}`);
      this.showAreaFlag = false;
      if (index === 1) {//当选择索引是1
        this.formData.areaFlag = 2;//则选中城市类型为 郑州
      }
      this.$toast(`焱火${this.areaFlagColumnList[index].text}!`);
    },
    //处理报名
    handleSubmit() {
      if (this.agreed) {
        // 提交表单的逻辑
        AddEnrollment(this.formData).then((res) => {
          if (res.code === 200) {
            this.$toast("恭喜您,报名成功！");
            // // 响应后设置registrationNumber和showSuccess
            this.registrationNumber = res.data.studentIdentity;//Math.floor(1000 + Math.random() * 9000); // 生成一个四位数
            this.showSuccess = true;
            // 延时执行 页面跳转
            setTimeout(this.redirectToRoute('Success', res.data), 2000);
          } else {
            // this.$toast(res.message);
            // this.$toast(res.data[0] + "," + res.data[1] + "!");
            this.$toast(res.data + "!");
          }
        });
      } else {
        this.$dialog.alert({
          message: '请勾选下方"同意"安全责任书的签订,报名就差一步啦！',
        });
        // alert('请勾选下方"同意"安全责任书的签订,报名就差一步啦！');
      }

    },
    //路由跳转到某页面
    redirectToRoute(name, data) {
      // 提交表单逻辑，成功后调用this.$router.push
      this.$router.push({name: name, params: {data: data}});
    }
  },
  //计算属性
  computed: {},

  //组件内使用 路由前置守卫
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 更改页面的标题
      if (vm.$route.meta && vm.$route.meta.title) {
        document.title = vm.$route.meta.title;
      }
    });
  },
  // 如果需要基于组件内的数据动态改变标题，也可以使用 watch 监听 $route 的变化
  watch: {
    '$route'(to, from) {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title;
      }
    }
  }
};
</script>


<style scoped>
/*表单元素*/
.formItem {
  width:80%;
  margin: 10px 50px;
  font-size: large;
}

/*表单容器样式*/
.signup-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /*background-color: #fff;*/
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*图片样式*/
.signup-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* 提交成功后的样式 */
.success-message {
  text-align: center;
  margin-top: 20px;
  font-size: large;
}

/*免责声明 文字样式*/
.retract {
  text-indent: 2%;
  font-weight: 400;
  text-align: left;
  padding: 0 30px;
  min-height: 100px;
}
</style>
