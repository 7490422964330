<template>
  <div class="fireworks">
    <h1>恭喜报名成功</h1>
    <h2>参赛编号：{{ data.studentIdentity }}</h2>
    <img :src="encodedImageUrl" alt="签到图片">
    <div>
<!--      <p>活动主题：2024“焱火杯”第七届亲子健康跑拉练赛-{{ data.className }}</p>-->

      <p>报名学生：{{ data.name }}-{{ data.parentPhone }}</p>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: ['data'],
  computed: {//计算属性
    // encodedUrl() {
    //   return `https://example.com/registration?name=${encodeURIComponent(this.data.name)}&email=${encodeURIComponent(this.data.email)}`;
    // },
    encodedImageUrl() {
      // 根据实际情况生成或获取图片URL
      return this.data.codeUrl;
    }
  }
}
</script>
<style scoped>
body, html {
  height: 100%;
  margin: 0;
  overflow: hidden;
  background: black;
}

.fireworks {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 50px;
  background: radial-gradient(circle, white, transparent);
  border-radius: 50%;
  box-shadow:
  0 0 50px 5px white,
  0 0 100px 10px white,
  0 0 150px 15px white,
  0 0 200px 20px white,
  0 0 250px 25px white,
  0 0 300px 30px white,
  0 0 350px 35px white;
  animation: explode 10000s infinite;
}

@keyframes explode {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0.8;
  }
}
</style>
