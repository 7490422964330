import Vue from 'vue'
import VueRouter from 'vue-router'
import EnrollmentForm from "@/views/EnrollmentForm.vue";
import EnrollmentSuccess from "@/views/EnrollmentSuccess";
import EnrollmentShenYang from "@/views/EnrollmentShenYang";
import EnrollmentDongYing from "@/views/EnrollmentDongYing";
import EnrollmentShenYang20240810TiCe from "@/views/EnrollmentShenYang20240810TiCe";
import EnrollmentZhengZhou20240810TiCe from "@/views/EnrollmentZhengZhou20240810TiCe";
import UpdateStudent from "@/views/UpdateStudent";
import Contract from "@/views/SignContract";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'yh1',
        component: EnrollmentForm,
        meta: {
            title: '2024“焱火杯”第七届亲子健康跑拉练赛'
        }
    },
    {
        path: '/yh',
        name: 'yh',
        component: EnrollmentForm,
        meta: {
            title: '2024“焱火杯”第七届亲子健康跑拉练赛'
        }
    },
    {
        path: '/shenyang',
        name: 'shenyang',
        component: EnrollmentShenYang,
        meta: {
            title: '2024“焱火杯”第七届亲子健康跑拉练赛'
        }
    },
    {
        path: '/dongying',
        name: 'dongying',
        component: EnrollmentDongYing,
        meta: {
            title: '2024“焱火杯”第七届亲子健康跑拉练赛'
        }
    },
    {
        path:'/sytc20240810',
        name: 'sytc20240810',
        component:EnrollmentShenYang20240810TiCe,
        meta: {
            title: '焱火体育(沈阳)2024全真模拟体测'
        }
    },
    {

        path:'/signContract',
        name: 'signContract',
        component:Contract,
        meta: {
            title: '签署合同'
        }
    },
    {
        path:'/zztc20240810',
        name: 'zztc20240810',
        component:EnrollmentZhengZhou20240810TiCe,
        meta: {
            title: '焱火体育(郑州)2024全真模拟体测'
        }
    },
    {
        path:'/updateStudent',
        name: 'updateStudent',
        component:UpdateStudent,
        meta: {
            title: '学员信息'
        }
    },
    {
        path: '/success',
        name: 'Success',
        component: EnrollmentSuccess,
        props: true
    },
]
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.afterEach((to, from) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router
